import React from "react";
import Maintenance from "./pages/Maintenance";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Maintenance />
    </div>
  );
}

export default App;
