import React from "react";

const Maintenance = () => {
  const pageStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
    backgroundColor: "#f4f4f4",
    color: "#333",
    fontFamily: "Arial, sans-serif",
  };

  const headingStyle = {
    fontSize: "2.5rem",
    marginBottom: "1rem",
  };

  const subheadingStyle = {
    fontSize: "1.2rem",
  };

  const iconStyle = {
    fontSize: "4rem",
    color: "#ffcc00",
  };

  return (
    <div style={pageStyle}>
      <div style={iconStyle} className="App-logo">
        ⚙️
      </div>
      <h1 style={headingStyle}>Kami Dalam Penyelenggaraan</h1>
      <p style={subheadingStyle}>
        Laman web kami sedang menjalani penyelenggaraan. <br />
        Kami akan kembali sebentar lagi. Terima kasih atas kesabaran anda!
      </p>
      <br />
      <br />
      <h1 style={headingStyle}>
        <i>We’re Under Maintenance</i>
      </h1>
      <p style={subheadingStyle}>
        <i>
          Our website is currently undergoing maintenance. <br />
          We’ll be back shortly. Thank you for your patience!
        </i>
      </p>
    </div>
  );
};

export default Maintenance;
